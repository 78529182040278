<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <div v-if="wallets.length" class="my-wallets">
        <h5>My Wallets</h5>

        <DataTable
          :value="wallets"
          dataKey="id"
          v-model:wallets="wallets"
          responsiveLayout="scroll"
        >
          <Column
            header="ownerAddress"
            field="ownerValue"
            style="word-break: break-all"
            :sortable="true"
          />
          <Column header="amount" field="amount" style="word-break: break-all">
            <template #body='slot'>
              <OutputDecimals :decimals-value='slot.data.amount' :decimals='slot.data.decimals'/>
            </template>
          </Column>

          <Column
            header="amountBlocked"
            field="amountBlocked"
            style="word-break: break-all"
          >
            <template #body='slot'>
              <OutputDecimals :decimals-value='slot.data.amountBlocked' :decimals='slot.data.decimals'/>
            </template>
          </Column>
          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
          >
            <template style="width: 130px" #body="slot">
              <div data-help="Open">
                <Button
                  icon="pi pi-folder-open ert"
                  class="p-button-rounded p-button-warning m-1"
                  @click="open(slot.data)"
                />
              </div>
              <div data-help="Send">
                <Button
                  icon="pi pi-sign-out"
                  class="send p-button-rounded p-button-warning m-1"
                  @click="send(slot.data)"
                />
              </div>
              <div data-help="Sale">
                <Button
                  icon="pi pi-dollar"
                  class="sale p-button-rounded p-button-warning m-1"
                  @click="sale(slot.data)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-wallets">
        <p>Not any wallets yet</p>
      </div>
    </div>
    <!-- Dialogs -->

    <Dialog
      v-model:visible="openDialog"
      :style="{ width: '450px' }"
      header="Open"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error">You open</p>
        <p class="text-center">auToken: {{ this.item.auToken.name }}</p>
        <p class="text-center">
          ownerAddress: {{ this.item.ownerAddress.value }}
        </p>
        <p class="text-center">amount: {{ this.item.amount }}</p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="openDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="openConfirm"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="sendDialog"
      :style="{ width: '450px' }"
      header="Send"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error">You want to send</p>
        <p class="text-center">auToken: {{ this.item.auToken.name }}</p>
        <p class="text-center">
          ownerAddress: {{ this.item.ownerAddress.value }}
        </p>
        <p class="text-center">amount: {{ this.item.amount }}</p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="sendDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="sendConfirm"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="saleDialog"
      :style="{ width: '450px' }"
      header="Sale"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error">You want to sale</p>
        <p class="text-center">auToken: {{ this.item.auToken.name }}</p>
        <p class="text-center">
          ownerAddress: {{ this.item.ownerAddress.value }}
        </p>
        <p class="text-center">amount: {{ this.item.amount }}</p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="saleDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saleConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import OutputDecimals from '@/components/edit/OutputDecimals'

export default {
  components: { OutputDecimals },
  name: 'Wallet',
  head() {
    return {
      title: 'Wallet',
    }
  },
  data() {
    return {
      wallets: [],
      ethList: [],
      tokenList: [],
      item: {},
      userId: this.$auth.$vm.state.data.id, // В целом не нужно
      openDialog: false, // открывает диалог open
      sendDialog: false, // открывает диалог send
      saleDialog: false, // открывает диалог sale
    }
  },
  async mounted() {
    await this.getTokens()
    await this.getEthList();
    await this.getWallets();
  },
  methods: {
    async getWallets() {
      try{
        const resp = await this.axios.get('api/au_owners');
        const allOwner = resp.data;
        for (const item of allOwner)
        {
          item.ownerValue = this.getEthValue(item.ownerAddress);
          item.decimals = this.getTokenDecimals(item.auToken)
          this.wallets.push(item);
        }
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in au_owners api call',
          group: 'info',
          life: 3000,
        });
      }
    },

    async getEthList()
    {
      try{
        const resp = await this.axios.get("/api/eth_addresses");
        const allEth = resp.data;
        for (const item of allEth)
        {
          this.ethList.push({id: item.id, value: item.value});
        }
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in eth_addresses api call',
          group: 'info',
          life: 3000,
        });
      }
    },

    getEthValue(value){
      let id = value.id;
      //check value iri or object
      if (id !== undefined) return value.value;
      id = value.slice(value.lastIndexOf('/') + 1);
      const eth = this.ethList.find(item => item.id == id);
      if (eth === undefined) return "-";
      return eth.value;
    },

    async getTokens()
    {
      try{
        const resp = await this.axios.get("api/au_tokens");
        const tokenData = resp.data;
        for (const item of tokenData)
        {
          this.tokenList.push({
            id: item.id,
            decimals: item.decimals === undefined ? 0 : item.decimals
          });
        }
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in au_tokens api call',
          group: 'info',
          life: 3000,
        })
      }
    },

    getTokenDecimals(value){
      let id = value.id;
      //check value iri or object
      if (id !== undefined) return value.decimals;
      id = value.slice(value.lastIndexOf('/') + 1);
      const token = this.tokenList.find(item => item.id == id);
      if (token === undefined) return 0;
      return token.decimals;
    },

    open(value) {
      this.openDialog = true
      this.item = value
      console.log('open', value)
    },
    send(value) {
      this.sendDialog = true
      this.item = value
      console.log('send', value)
    },
    sale(value) {
      this.saleDialog = true
      this.item = value
      console.log('sale', value)
    },
    openConfirm() {
      this.openDialog = false
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Wallet open',
        group: 'info',
        life: 3000,
      })
    },
    sendConfirm() {
      this.sendDialog = false
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Wallet send',
        group: 'info',
        life: 3000,
      })
    },
    saleConfirm() {
      this.saleDialog = false
      this.$toast.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Wallet sale',
        group: 'info',
        life: 3000,
      })
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
</style>
